<script>
import '~/assets/css/styles.css'
</script>
<template>
    <div class="">
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div class="container-xl px-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-5">
                                <div class="card shadow-lg border-0 rounded-lg mt-5">
                                    <!-- <NuxtLink to="/" class="position-absolute m-1" v-if="this.$route.name != 'index'">
                                        <div class="m-3"><i class="fa-solid fa-chevron-left"></i></div>
                                    </NuxtLink> -->
                                    <div class="card-header d-flex justify-content-center">
                                        <div class="my-1 text-center">
                                            <img class="w-25 mr-2" src="~/assets/img/allodons.png">
                                            <sup class="text-black font-weight-bold"
                                                v-if="this.$route.name == 'organizations'">
                                                <i class="fa-solid fa-heart"></i>
                                                Associations
                                            </sup>
                                            <sup class="text-black font-weight-bold"
                                                v-if="this.$route.name == 'donors'">
                                                <i class="fa-solid fa-user"></i>
                                                Donateurs
                                            </sup>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <slot />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>